import "@app/styles/globals.scss";
import { usePathname } from "next/navigation";

import type { AppProps } from "next/app";
import { wrapper } from "@app/store/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Montserrat } from "next/font/google";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { AuthCheck } from "@components/Features/Auth/AuthCheck";
const font = Montserrat({
    subsets: ["cyrillic"]
});

function App({ Component, ...rest }: AppProps) {
    const router = useRouter();
    const pathname = usePathname();
    // const user = useSelector((state: AppState) => state.auth.currentUser);
    // console.log(user)

    useEffect(() => {
        window.scrollTo(0, 0);
        // debugger
    }, [pathname]);
    const { store, props } = wrapper.useWrappedStore(rest);
    return (
        <>
            <Provider store={store}>
                <AuthCheck>
                    <Component {...props.pageProps} />
                </AuthCheck>
            </Provider>
        </>
    );
}

export default App;
